import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrokenLinkCorrectorProps } from '../../types';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import './styles.scss';

const FAQItem: FC<BrokenLinkCorrectorProps> = ({ title, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="mb-0">
          <button
            className={classNames('faq_btn faq_btn_link', {
              collaped: !isOpen,
            })}
            onClick={handleOpen}
          >
            {title}
            <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} className="icon" />
          </button>
        </h5>
      </div>
      {isOpen && (
        <div className="card-body">
          {text}
        </div>
      )}
    </div>
  );
};

export default FAQItem;
