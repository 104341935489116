import React from 'react';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import FAQItem from '../../components/FAQ-item';
import './styles.scss';

const FAQ = () => {
  return (
    <div className="content-wrapper">
      <Layout>
        <PageHero title="Hexowatch FAQ" subtitle="On this page you can get the answers to FAQs" />
        <Section>
          <div className="col-12 d-block mb_20" style={{maxWidth: '1200px', width: '100%'}}>
            <FAQItem
              title="How do the checks work and how many pages can I check?"
              text={
                <>
                  <p>
                    The amount of pages you can check depends on how frequently you need to check a page and the plan
                    you purchase. For example: 1 page checked daily would consume 30 checks a month 1 page checked every
                    12 hours would consume 60 checks a month 1 page checked every hour would consume 720 checks a month
                    1 page checked every 10 minutes would consume 4320 checks a month
                  </p>
                  <p>Here is an example of how many checks you can make per plan:</p>
                  <p style={{fontWeight: 700}}>Pro plan (4,500 page checks/month):</p>
                  <p>
                    4500 pages checked monthly Or 1035 pages checked weekly Or 150 pages checked daily Or 75 pages
                    checked every 12 hours Or 6 pages checked every hour Or 1 page every 15 minutes
                  </p>
                  <p style={{fontWeight: 700}}>Business plan (10,000 page checks/month):</p>
                  <p>
                    10,000 pages checked monthly Or 2300 pages checked weekly Or 333 pages checked daily Or 166 pages
                    checked every 12 hours Or 13 pages checked every hour Or 3 page every 15 minutes
                  </p>
                  <p style={{fontWeight: 700}}>Business + plan (25,000 page checks/month):</p>
                  <p>
                    25,000 pages checked monthly Or 5753 pages checked weekly Or 833 pages checked daily Or 416 pages
                    checked every 12 hours Or 34 pages checked every hour Or 8 pages every 15 minutes
                  </p>
                </>
              }
            />
            <FAQItem
              title="Does Hexowatch work for websites which are using a Javascript framework like React, Angular, Vue to render the HTML?"
              text={
                <p>
                  Yes, websites which are using Javascript frameworks like React, Angular and Vue to render the HTML
                  should work. But there is no absolute answer for all cases, so the best way is to open/preview the
                  page/website with our tool, you can sign up for an account - if it opens as it should then yes if not
                  contact our support team and we can check it out.
                </p>
              }
            />
            <FAQItem
              title="I’m in the B2B space, how can Hexowatch help me?"
              text={
                <p>
                  There are many applications where you can use Hexowatch to free up time and stay ahead of the game for
                  both B2C and B2B spaces. If you are in the B2B space, you can use Hexowatch to monitor price lists,
                  suppliers, clients and be notified when anything changes. You can use it to follow public changelog
                  pages and be notified when a new update is released for the software or product that you sell or use.
                  If you have any competitors, you can monitor their homepage, news, or pricing pages for changes. Or if
                  you have your own website, you can setup a few visual monitors on different pages, so that you can be
                  alerted if any recent website update caused any visual issues or broke anything.
                </p>
              }
            />
            <FAQItem
              title="Can Hexowatch monitor a whole website for changes?"
              text={
                <p>
                  Hexowatch monitors specific pages but also provides the ability to monitor URLs in bulk or entire
                  sitemaps for changes.
                </p>
              }
            />
            <FAQItem
              title="What are the US / EU / Asia server locations for?"
              text={
                <>
                  <p>
                    The business and above plan let you specify where the checks are coming from and you can pick US,
                    EU, or Asia. Otherwise, it's from the US or another location based on load balancing availability at
                    the moment the check is made.
                  </p>
                  <p>
                    This feature enables you to check a website as if you were browsing from a specific geographic
                    location, so for example, if you want to check a website that displays prices in USD for US
                    visitors, you can specify that we should visit that web page from the USA.
                  </p>
                </>
              }
            />
            <FAQItem
              title="Can we use this to be notified when limited edition items are added in a website/sold out size is back in stock - for example Jordan shoes?"
              text={<p>Yes, this is a popular use case for our non-business customers.</p>}
            />
            <FAQItem
              title="What is the data retention policy?"
              text={
                <p>
                  Hexowatch stores collected data for a minimum of 3 months and you can export/download/copy any data
                  during that period.
                </p>
              }
            />
            <FAQItem
              title="Can Hexowatch be used on websites that are password protected or have a member-only area?"
              text={
                <>
                  {' '}
                  <p>
                    Hexowatch supports password protected websites via custom actions on the visual monitor and HTML
                    element monitors
                  </p>
                  <p>
                    Custom actions enable you to type login credentials and submit forms as part of the change
                    monitoring flow to access any password protected page (as long as it is within their TOS).{' '}
                  </p>
                </>
              }
            />
            <FAQItem
              title="How is Hexowatch different from Hexometer?"
              text={
                <>
                  <p>
                    Hexowatch is more like a recon drone which lets you monitor any web page for changes, and you can
                    use it to monitor how your pages display (for defacement protection or to be notified when an update
                    has caused a visual bug) as well as use it to monitor your competitors, suppliers and just about any
                    website.
                  </p>
                  <p>
                    What makes Hexowatch really powerful is how you can specify what types of changes to look for, and
                    how frequently you want to check these. For example, you can specify to be notified when a visual
                    change is detected (in case a recent update caused a bug), or you can be alerted only when a price
                    is updated when new content has been published, when a website adds a new script or changes their
                    tech stack, or get a notification when a backlink partner stops linking back to you.{' '}
                  </p>
                  <p>
                    All changes trigger a snapshot which is archived in the cloud for comparison and you can be notified
                    by email or other notification channels connected when a change is detected.
                  </p>
                </>
              }
            />
            <FAQItem
              title="What websites can I check?"
              text={
                <>
                  <p>
                    Hexowatch can check most website pages but is NOT designed to monitor social media networks (like
                    Facebook, Instagram, LinkedIn, etc), search engines (like Google, Bing, etc), or large eCommerce
                    platforms (like Amazon, eBay, etc).
                  </p>
                </>
              }
            />
            <FAQItem
              title="What if a page doesn’t display correctly when monitoring?"
              text={
                <>
                  <p>
                    Some pages which use older coding standards or non-standard HTML or JS scripts can be problematic
                    for our custom browser. If you encounter any issues, please get in touch with our team at &nbsp;
                    <a href="mailto:support@hexowatch.com" target="_blank" rel="noopener">
                      support@hexowatch.com
                    </a>{' '}
                    so they can investigate and troubleshoot the issue.
                  </p>
                </>
              }
            />
            <FAQItem
              title="What’s the difference between the plans?"
              text={
                <>
                  <p>
                    PRO provides 4500 checks per month, checks up to every 15 minutes, custom actions, webhooks and all
                    our integrations.
                  </p>
                  <p>
                    Business provides 10,000 checks per month, checks up to every 5 minutes as well as user-selectable
                    server locations (to check pages as if you were in the US, EU or Asia){' '}
                  </p>
                  <p>Business + provides all the above with 25,000 checks a month</p>
                </>
              }
            />
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default FAQ;
